import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import 'bootstrap/scss/bootstrap.scss'
import '@/assets/less/bg.less'

const app = createApp(App)
app.use(router)

app.mount('div.app')