<template>
  <DefaultLayout v-if="$route.path != '/login' && current_user">
    <router-view></router-view>
  </DefaultLayout>
  <Login v-if="$route.path == '/login' || !current_user">
    <LoginPage></LoginPage>
  </Login>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
import DefaultLayout from '@/layouts/Default.vue';
import Login from '@/layouts/Login.vue';
import { current_user } from '@/store/auth';
import { defineAsyncComponent } from 'vue';

const LoginPage = defineAsyncComponent(() => import('@/pages/login/index.vue'))


const $route = useRoute()

</script>
