const __pages_import_0__ = () => import("/src/pages/usuarios/novo.ts");
const __pages_import_1__ = () => import("/src/pages/usuarios/index.vue");
const __pages_import_2__ = () => import("/src/pages/setores/index.vue");
const __pages_import_3__ = () => import("/src/pages/resultados/index.vue");
const __pages_import_4__ = () => import("/src/pages/modelo/index.vue");
const __pages_import_5__ = () => import("/src/pages/modelo/editar.vue");
const __pages_import_6__ = () => import("/src/pages/lojas/novo.ts");
const __pages_import_7__ = () => import("/src/pages/lojas/index.vue");
const __pages_import_8__ = () => import("/src/pages/login/index.vue");
const __pages_import_9__ = () => import("/src/pages/inventarios/novo.ts");
const __pages_import_10__ = () => import("/src/pages/inventarios/index.vue");
const __pages_import_11__ = () => import("/src/pages/home/index.vue");
import __pages_import_12__ from "/src/pages/index.vue";
const __pages_import_13__ = () => import("/src/pages/resultados/[inventario_id].vue");
const __pages_import_14__ = () => import("/src/pages/home/[secao_id]/auditagem.vue");
const __pages_import_15__ = () => import("/src/pages/home/[secao_id]/contagem-ciclica.vue");
const __pages_import_16__ = () => import("/src/pages/inventarios/editar/[id].vue");
const __pages_import_17__ = () => import("/src/pages/lojas/editar/[id].vue");
const __pages_import_18__ = () => import("/src/pages/secoes/[inventario_id]/[setor_id].vue");
const __pages_import_19__ = () => import("/src/pages/usuarios/editar/[id].vue");

const routes = [{"name":"usuarios-novo","path":"/usuarios/novo","component":__pages_import_0__,"props":true},{"name":"usuarios","path":"/usuarios","component":__pages_import_1__,"props":true},{"name":"setores","path":"/setores","component":__pages_import_2__,"props":true},{"name":"resultados","path":"/resultados","component":__pages_import_3__,"props":true},{"name":"modelo","path":"/modelo","component":__pages_import_4__,"props":true},{"name":"modelo-editar","path":"/modelo/editar","component":__pages_import_5__,"props":true},{"name":"lojas-novo","path":"/lojas/novo","component":__pages_import_6__,"props":true},{"name":"lojas","path":"/lojas","component":__pages_import_7__,"props":true},{"name":"login","path":"/login","component":__pages_import_8__,"props":true},{"name":"inventarios-novo","path":"/inventarios/novo","component":__pages_import_9__,"props":true},{"name":"inventarios","path":"/inventarios","component":__pages_import_10__,"props":true},{"name":"home","path":"/home","component":__pages_import_11__,"props":true},{"name":"index","path":"/","component":__pages_import_12__,"props":true},{"name":"resultados-inventario_id","path":"/resultados/:inventario_id","component":__pages_import_13__,"props":true},{"name":"home-secao_id-auditagem","path":"/home/:secao_id/auditagem","component":__pages_import_14__,"props":true},{"name":"home-secao_id-contagem-ciclica","path":"/home/:secao_id/contagem-ciclica","component":__pages_import_15__,"props":true},{"name":"inventarios-editar-id","path":"/inventarios/editar/:id","component":__pages_import_16__,"props":true},{"name":"lojas-editar-id","path":"/lojas/editar/:id","component":__pages_import_17__,"props":true},{"name":"secoes-inventario_id-setor_id","path":"/secoes/:inventario_id/:setor_id","component":__pages_import_18__,"props":true},{"name":"usuarios-editar-id","path":"/usuarios/editar/:id","component":__pages_import_19__,"props":true}];

export default routes;